<mat-sidenav-container class="main-container" *ngIf="true" [hasBackdrop]="false">

  <!-- OVERVIEW SLIDE -->
  <mat-sidenav class="overview" mode="over" [opened]="overview">
    <mat-card appearance="outlined" (click)="goToSlide(i)" style="padding: 1px 15px;"
      *ngFor="let item of appSlidesData; let i = index">
      <p style="color: #004684;cursor: pointer;border-bottom: #004684;">{{i+1}}. - {{item.SlideDescription}}</p>
      <!-- <img [src]="'http://www.bipresents.com/' + item.SlideBGFileName" alt="overview"
          style="max-width: 250px;cursor: pointer;"> -->
    </mat-card>
  </mat-sidenav>

  <mat-sidenav-content style="overflow-y: hidden;">
    <div class="container">

      <!-- MENU ICON -->
      <div fxLayout="row" fxLayoutAlign="center center">
        <button *ngIf="!mainMenu" class="menu-icon" (click)="toogleMenus()" mat-icon-button color="red">
          <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
      </div>

      <button (click)="moveBackward()" [class.menu-left-icon-wide-post]="wide &&  createPostIt"
        [class.menu-left-icon-wide]="wide &&  !createPostIt" [class.menu-left-icon]="!wide && !createPostIt"
        [class.menu-left-icon-post]="!wide && createPostIt" disableRipple mat-icon-button color="red">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <button (click)="moveForward()" [class.menu-right-icon]="!wide"
        [class.menu-right-icon-wide]="wide &&  !createPostIt" [class.menu-right-icon-wide-post]="wide &&  createPostIt"
        [class.menu-right-icon-post]="!wide &&  createPostIt" disableRipple mat-icon-button color="red">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>


      <!-- COMMENTS BOX -->
      <div class="comment-to-top" cdkDrag cdkDragRootElement=".cdk-overlay-pane" fxLayout="col" *ngIf="isCommentBox"
        fxLayoutAlign="center center">
        <div fxFlex class="mat-elevation-z4 comment-box">
          <div cdkDragHandle style="height: 20px; width: 100%;"></div>
          <div class="col" >
            <div class="row">
              <div class="handle" style="float: left; font-size: 9px;">.</div>
              <button (click)="displayCommentBox()" mat-stroked-button style="color: #004684;
                 position: absolute;
                 right: 7px;
                 z-index: 9999;
                 top: 185px;" color="primary" aria-label="LOG IN" [disabled]="loginForm.invalid">
                CANCEL
              </button>
              <button (click)="comment()" mat-stroked-button style="color: #004684;
                 position: absolute;
                 right: 100px;
                 z-index: 9999;
                 top: 185px;" color="primary" aria-label="LOG IN" [disabled]="loginForm.invalid">
                SUBMIT
              </button>
              <form name="loginForm" [formGroup]="loginForm" novalidate class="open-close-container">
                <mat-form-field class="comment-field" appearance="outline">
                  <mat-label>Comment's Box</mat-label>
                  <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="4" [(ngModel)]="commentBoxText"
                    formControlName="suma" cdkAutosizeMaxRows="4" appearance="outline" id="commentBox"></textarea>
                </mat-form-field>
              </form>
            </div>
          </div>
        </div>
      </div>

      <!-- COMMENTS BOX -->
      <!-- <div fxLayout="col" *ngIf="!isCommentBox" fxLayoutAlign="center center">
          <div fxFlex class="mat-elevation-z4 comment-box">
            <div class="col">
              <div class="row">
                <button (click)="displayCommentBox()" mat-stroked-button style="color: #004684;
                      position: absolute;
                      right: 7px;
                      top: 201px;" color="primary" aria-label="LOG IN" [disabled]="loginForm.invalid">
                  CANCEL
                </button>
                <button (click)="comment()" mat-stroked-button style="color: #004684;
                      position: absolute;
                      right: 100px;
                      top: 201px;" color="primary" aria-label="LOG IN" [disabled]="loginForm.invalid">
                  SUBMIT
                </button>
                <form name="loginForm" [formGroup]="loginForm" novalidate class="open-close-container">
                  <mat-form-field class="comment-field " appearance="outline">
                    <mat-label>Comment's Box</mat-label>
                    <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="4" [(ngModel)]="commentBoxText"
                      cdkAutosizeMaxRows="4" appearance="outline" formControlName="suma"></textarea>
                  </mat-form-field>
                </form>
              </div>
            </div>
          </div>
        </div> -->

      <!-- CORNER MENUS -->

      <div *ngIf="!mainMenu" class="bar_fullscreen" class="corner_bar_menu">
        <button *ngIf="createPostIt" (click)="newBlankPostIt()" matTooltip="add post-it" matTooltipPosition="above"
          mat-icon-button color="warn" aria-label="aria">
          <mat-icon class="full-icon">note_add</mat-icon>
        </button>

        <button *ngIf="!createPostIt" (click)="bsr()" mat-icon-button matTooltip="add post its"
          matTooltipPosition="above" mat-icon-button color="warn" aria-label="aria">
          <mat-icon class="full-icon">lightbulb_outline</mat-icon>
        </button>
        <!-- <button mat-icon-button aria-label="Proprietary" style="width: 200px;">
            Proprietary & Confidential
          </button> -->
        <button (click)="toogleMenus()" matTooltip="menu" matTooltipPosition="above" mat-icon-button color="warn"
          aria-label="aria">
          <mat-icon class="full-icon">menu</mat-icon>
        </button>
      </div>

      <div *ngIf="mainMenu" class="bar_fullscreen" class="corner_bar_menu">
        <button (click)="home()" mat-icon-button matTooltip="home" matTooltipPosition="above">
          <mat-icon class="full-icon">home</mat-icon>
        </button>
        <button *ngIf="!createPostIt" (click)="bsr()" mat-icon-button matTooltip="brainstorming"
          matTooltipPosition="above" mat-icon-button color="warn" aria-label="aria">
          <mat-icon class="full-icon">lightbulb_outline</mat-icon>
        </button>
        <button *ngIf="createPostIt" (click)="newBlankPostIt()" matTooltip="add post-it" matTooltipPosition="above"
          mat-icon-button color="warn" aria-label="aria">
          <mat-icon class="full-icon">note_add</mat-icon>
        </button>

        <button (click)="openFullscreen()" matTooltip="full-screen" matTooltipPosition="above" mat-icon-button
          color="warn" aria-label="aria">
          <mat-icon class="full-icon">aspect_ratio</mat-icon>
        </button>
        <button (click)="sideMenu()" matTooltip="overview" matTooltipPosition="above" mat-icon-button color="warn"
          aria-label="aria">
          <mat-icon class="full-icon">search</mat-icon>
        </button>
        <button *ngIf="!createPostIt" (click)="displayCommentBox()" mat-icon-button matTooltip="slide comments"
          matTooltipPosition="above">
          <mat-icon class="full-icon">comment</mat-icon>
        </button>
        <button *ngIf="!isNSR" (click)="openDialog('qr_code', 'do')" matTooltip="qr code" matTooltipPosition="above"
          mat-icon-button color="warn" aria-label="aria">
          <mat-icon class="full-icon">qr_code</mat-icon>
        </button>

        <button *ngIf="!isNSR" (click)="openDialog('mobileInfo', 'do')" matTooltip="mobile app" mat-icon-button
          color="red">
          <mat-icon class="full-icon">phone_iphone</mat-icon>
        </button>
        <button *ngIf="createPostIt" (click)="diplayFontSizeSlider = !diplayFontSizeSlider" matTooltip="font size"
          matTooltipPosition="above" mat-icon-button color="warn" aria-label="aria">
          <mat-icon class="full-icon">font_download</mat-icon>
          <mat-slider #slider *ngIf="diplayFontSizeSlider" showTickMarks discrete (change)="setFontSize()"
            class="slider-font-size" thumbLabel tickInterval="2" min="20" max="38">
            <input matSliderThumb [(ngModel)]="font_size">
          </mat-slider>
        </button>
        <button *ngIf="createPostIt" (click)="theme()" mat-icon-button matTooltip="toggle theme"
          matTooltipPosition="above">
          <mat-icon class="full-icon">casino</mat-icon>
        </button>
        <button *ngIf="createPostIt" (click)="toggleNamebox()" mat-icon-button matTooltip="names box"
          matTooltipPosition="above">
          <mat-icon class="full-icon">bento</mat-icon>
        </button>
        <button (click)="wideScreenView()" mat-icon-button matTooltip="Wide screen" matTooltipPosition="above"
          mat-icon-button color="warn" aria-label="aria"> <svg class="full-icon" style="height: 20px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
            <path
              d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM224 160c6.7 0 13 2.8 17.6 7.7l104 112c6.5 7 8.2 17.2 4.4 25.9s-12.5 14.4-22 14.4H120c-9.5 0-18.2-5.7-22-14.4s-2.1-18.9 4.4-25.9l104-112c4.5-4.9 10.9-7.7 17.6-7.7z" />
          </svg>
        </button>
        <button (click)="showHotKeys = true" matTooltip="info" matTooltipPosition="above" mat-icon-button color="warn"
          aria-label="aria">
          <mat-icon class="full-icon">help</mat-icon>
        </button>
        <button disabled mat-icon-button aria-label="page" style="width: 100px;font-size: 23px;color: grey !important;">
          {{pageCounter}}
        </button>
        <button (click)="mainMenu = false" matTooltip="hide menu" matTooltipPosition="above" mat-icon-button
          color="warn" aria-label="aria">
          <mat-icon class="full-icon">keyboard_arrow_right</mat-icon>
        </button>

      </div>
      <!-- <div *ngIf="mainMenu" class="bar_fullscreen" class="corner_bar_menu">
          <button mat-icon-button aria-label="Proprietary" style="width: 200px;">
            Proprietary & Confidential
          </button>
        </div> -->

      <!-- PRESENTATION MENU-->
      <div *ngIf="!createPostIt" fxLayoutAlign="center center" class="col">
        <div class=" mat-elevation-z12" [ngClass]="{'slide-box': !wide, 'slide-box-width': wide}"
          [style.background]="slideBackground">


          <mat-toolbar class="toolbar mat-elevation-z4 bounceIn" *ngIf="false">
            <!-- <mat-toolbar class="toolbar mat-elevation-z4 bounceIn" *ngIf="mainMenu"> -->

            <button (click)="home()" mat-icon-button aria-label="Example icon-button with a heart icon">
              <mat-icon>home</mat-icon>
            </button>

            <button (click)="bsr()" mat-icon-button color="primary" aria-label="Example icon-button with a heart icon">
              <mat-icon>lightbulb_outline</mat-icon>
            </button>

            <input class="search" placeholder="search" type="text" (input)="searchTerm($event.target.value)">

            <button (click)="moveBackward()" mat-icon-button color="primary"
              aria-label="Example icon-button with a heart icon">
              <mat-icon>chevron_left</mat-icon>
            </button>

            <button (click)="moveForward()" mat-icon-button color="primary"
              aria-label="Example icon-button with a heart icon">
              <mat-icon>chevron_right</mat-icon>
            </button>

            <button (click)="displayCommentBox()" mat-icon-button aria-label="Example icon-button with a heart icon">
              <mat-icon>comment</mat-icon>
            </button>

            <button *ngIf="!isNSR" (click)="openDialog('mobileInfo', 'do')" mat-icon-button color="primary"
              aria-label="Example icon-button with a heart icon">
              <mat-icon>phone_iphone</mat-icon>
            </button>


            <button (click)="sideMenu()" mat-icon-button color="accent"
              aria-label="Example icon-button with a heart icon">
              <mat-icon>import_contacts</mat-icon>
            </button>

            <button mat-icon-button aria-label="Proprietary" style="width: 200px;">
              Proprietary & Confidential
            </button>

            <button (click)="bsr()" mat-icon-button aria-label="page" style="width: 100px;font-size: 23px;">
              {{pageCounter}}
            </button>

            <button (click)="displayHelp(false)" mat-icon-button color="warn" aria-label="aria">
              <mat-icon style="color: green !important;">help</mat-icon>
            </button>

            <button (click)="mainMenu = false" mat-icon-button color="warn" aria-label="aria">
              <mat-icon style="color: grey !important;">keyboard_arrow_down</mat-icon>
            </button>


          </mat-toolbar>

        </div>

      </div>


      <div class="col searchy" [style.left]="searchBoxLeftProperty" *ngIf="appSearchSlidesData.length&&mainMenu">
        <mat-card appearance="outlined" (click)="goToSlideFromSearch(item.$id)"
          *ngFor="let item of appSearchSlidesData; let i = index">
          <p>{{item.SlideDescription}}</p>
          <img [src]="'http://www.bipresents.com/' + item.SlideBGFileName" alt="overview"
            style="max-width: 90px;cursor: pointer;">
        </mat-card>
        <hr>
      </div>


      <!-- POST-ITS -->
      <div *ngIf="createPostIt" fxLayoutAlign="center center" class="col">

        <div class="container">
          <div class="row">

            <div class="col-md-12">

              <!-- Brandstorm and Names toolbar -->
              <mat-toolbar [class.toolbar-postit-wide]="wide && createPostIt" [class.toptoolbar]="!wide"
                class=" mat-elevation-z12" color="primary">

                <div class="row">
                  <div (click)="theme()" class="col brandstorm"
                    style="color: white; font-size: 40px; font-family: Montserrat !important;">
                    Brainstorm
                  </div>
                  <div class="col " (click)="toggleNamebox()" [class.name-title]="!wide" [class.name-title-wide]="wide"
                    [matTooltip]="'Names Created: '+ nameCandidates.length" matTooltipPosition="right">
                    Names
                    <span *ngIf="nameCandidates.length > 0" style="position: absolute;    top: 6px; font-family: Montserrat !important;
                        right: 4px;" matBadgeColor="warn" [matBadgeHidden]="!(nameCandidates.length > 0)"
                      [matBadge]="nameCandidates.length" matBadgeSize="medium"></span>
                  </div>
                  <button [class.screen-button-wide]="wide" [class.screen-button]="!wide" mat-raised-button
                    *ngIf="isScreenButton&&isNSR" (click)="screenNames()">
                    <!-- <mat-icon>folder_special</mat-icon> -->
                    screen
                  </button>

                </div>
              </mat-toolbar>
            </div>
          </div>



          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-8">

                  <div class="presentation-box " [class.presentation-box-wide]="wide && createPostIt"
                    style="overflow-y: scroll;">
                    <!-- POST_REPEATER -->

                    <div class="row post-it-container "
                      [style.max-width]="namesBoxIndex == 1? '1320px': namesBoxIndex == 0 && wide?'931px': wide && createPostIt? '947px': '925px'"
                      dragula="TASKS" [dragulaModel]="conceptData.concepts"
                      (dragulaModelChange)="conceptData.concepts = $event">
                      <div style="z-index: 999;" [ngClass]="postItListTheme" *ngFor="let item of conceptData.concepts; let i = index;">

                        <mat-card appearance="outlined" class="mat-elevation-z4 post-card">
                          <div class="emoji-handle">
                            <!-- <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                              </svg> -->
                          </div>

                          <!-- TITLE -->
                          <mat-card-title>
                            <div class="post-title">
                              <div class="mat-h2" style="line-height: 100%;">{{item.concept}}<div class="handle"
                                  style="float: right;width: 250px;    font-size: 1px;">.</div>
                              </div>

                              <hr>
                            </div>

                          </mat-card-title>

                          <!-- HTML-CONTENT -->
                          <mat-card-content class="post-content" [ngStyle]="{'font-size': font_size_text}"
                            (click)="openDialog(item, 'edit')">
                            <div class="ql-editor card-list" [style.font-size]="font_size_text" [innerHTML]="item.html">
                            </div>
                          </mat-card-content>

                          <mat-icon class="deleteButton" (click)="openDialog(item, 'delete')">cancel</mat-icon>

                        </mat-card>

                      </div>


                    </div>
                  </div>
                </div>

                <!-- NAMES BOX 1 -->
                <div class="col-md-4" *ngIf="nameBox && nameBoxB">
                  <!-- NAMES  nameCandidates BOX -->
                  <!-- <button mat-button class="screen-button">screen</button> -->
                  <div [class.names-container-wide]="wide" [class.names-container]="!wide"
                    class=" bounceOut mat-elevation-z2" [ngStyle]="{'max-width': myMaxRWith  }">
                    <div *ngFor="let  nameCandidate of nameCandidates; let i = index">
                      <span *ngIf="!isScreeningNames" (dblclick)="openDialog(nameCandidate, 'deleteName')"
                        style="font-size:22px;margin: 10px;cursor: pointer;overflow: scroll;"
                        [matTooltip]="nameCandidate.Source" matTooltipPosition="right">
                        {{nameCandidate.Name}}
                      </span>
                      <span *ngIf="isScreeningNames" [innerHTML]="nameCandidate.html"
                        (dblclick)="openDialog(nameCandidate, 'deleteName')"
                        style="font-size:22px;margin: 10px;cursor: pointer;overflow: scroll;"
                        [matTooltip]="nameCandidate.Source" matTooltipPosition="right">
                      </span>
                      <div style="border-bottom: rgba(0,0,0,.12) 1px solid;width: 100%;text-align: center;"></div>
                    </div>
                  </div>

                  <form name="loginForm" [formGroup]="loginForm" novalidate [class.name-form-wide]="wide"
                    class="name-form mat-elevation-z4" fxLayoutAlign="center center">

                    <mat-form-field appearance="outline">
                      <input matInput type="New Name" placeholder="name1, name2, name3" formControlName="name"
                        [(ngModel)]="newName">
                    </mat-form-field>
                    <button style (click)="submitNewName(); newName=''" mat-icon-button color="success"
                      [class.button2]="nameIndexCounter==2" aria-label="icon" style="outline: none;
                          position: absolute;
                        outline: none;
                        font-size: 50px;
                        position: absolute;
                        top: 13px;
                        z-index: 1;
                        left: 239px;
                      display: flex;
                      justify-content: center;
                      align-items: center;">
                      <mat-icon class="play-arrow">play_arrow</mat-icon>

                    </button>
                    <p *ngIf="!isNSR" style="position: absolute;
                      top: 66px;
                      right: 56px;
                      font-weight: 500;">www.mynamepage.com/{{projectName}}</p>
                  </form>
                </div>


                <div class="col-md-4 " *ngIf="nameBox && !nameBoxB">
                  <!-- NAMES BOX 2-->
                  <div [class.names-container2]="!wide" [class.names-container2-wide]="wide">
                    <div *ngFor="let  nameCandidate of nameCandidates; let i = index">
                      <span *ngIf="!isScreeningNames" (dblclick)="openDialog(nameCandidate, 'deleteName')"
                        style="font-size:22px;margin: 10px;cursor: pointer;overflow: scroll;"
                        [matTooltip]="nameCandidate.Source" matTooltipPosition="right">
                        {{nameCandidate.Name}}
                      </span>
                      <span *ngIf="isScreeningNames" [innerHTML]="nameCandidate.html"
                        (dblclick)="openDialog(nameCandidate, 'deleteName')"
                        style="font-size:22px;margin: 10px;cursor: pointer;overflow: scroll;"
                        [matTooltip]="nameCandidate.Source" matTooltipPosition="right">
                      </span>
                      <div style="border-bottom: rgba(0,0,0,.12) 1px solid;width: 100%;text-align: center;"></div>
                    </div>
                  </div>
                  <form name="loginForm" [formGroup]="loginForm" novalidate class="name-form2 "
                    fxLayoutAlign="center center">

                    <mat-form-field appearance="outline">
                      <input matInput type="New Name" placeholder="name1, name2, name3" formControlName="name"
                        [(ngModel)]="newName">
                    </mat-form-field>
                    <button (click)="submitNewName(); newName=''" [class.form-button2]="namesBoxIndex == 0"
                      mat-icon-button color="success" aria-label="icon" style="outline: none;
                        position: absolute;
                      outline: none;
                      font-size: 50px;
                      position: absolute;
                      top: 24px;
                      z-index: 1;
                      left: 239px;
                      display: flex;
                      justify-content: center;
                      align-items: center;">
                      <mat-icon class="play-arrow">play_arrow</mat-icon>
                    </button>
                    <p style="position: absolute;
                      top: 60px;">www.mynamepage.com/{{projectName}}</p>
                  </form>
                </div>

              </div>
            </div>

            <div class="row">
              <!-- SEARCH RESULTS -->
              <!-- <div class="col" *ngIf="isSearching">
                    <div class="search-results">
                      <mat-card (click)="goToSlide(i)" class="example-card"
                        *ngFor="let item of appSearchSlidesData; let i = index">
                        <mat-card-header>
                          <mat-card-subtitle>{{item.SlideDescription}}</mat-card-subtitle>
                        </mat-card-header>
                        <img [src]="'http://www.bipresents.com/' + item.SlideBGFileName" alt="overview"
                          style="max-width: 100px;cursor: pointer;">
                      </mat-card>
                      <br>
                      <hr>
                    </div>
                  </div> -->

              <div class="col-md-12" fxLayoutAlign="center center">
                <!-- MENU BSR-->
                <mat-toolbar class="toolbar-postit mat-elevation-z4 bounceIn" *ngIf="false">
                  <!-- <mat-toolbar class="toolbar-postit mat-elevation-z4 bounceIn" *ngIf="mainMenu"> -->

                  <button (click)="home()" mat-icon-button aria-label="Example icon-button with a heart icon"
                    matTooltip="Home" matTooltipPosition="above">
                    <mat-icon>home</mat-icon>
                  </button>

                  <button matTooltip="brainstorming" matTooltipPosition="above" (click)="newBlankPostIt()"
                    mat-icon-button color="primary" aria-label="Example icon-button with a heart icon">
                    <mat-icon>note_add</mat-icon>
                  </button>

                  <input class="search" placeholder="search" type="text" (input)="searchTerm($event.target.value)">

                  <button (click)="moveBackward()" mat-icon-button color="accent"
                    aria-label="Example icon-button with a heart icon">
                    <mat-icon>chevron_left</mat-icon>
                  </button>

                  <button (click)="moveForward()" mat-icon-button color="warn"
                    aria-label="Example icon-button with a heart icon">
                    <mat-icon>chevron_right</mat-icon>
                  </button>


                  <button *ngIf="!isNSR" (click)="openDialog('mobileInfo', 'do')" mat-icon-button color="primary"
                    aria-label="Example icon-button with a heart icon">
                    <mat-icon>phone_iphone</mat-icon>
                  </button>


                  <button mat-icon-button aria-label="page" style="width: 100px;font-size: 23px;">
                    {{pageCounter}}
                  </button>

                  <button (click)="displayHelp(false)" mat-icon-button color="warn"
                    aria-label="Example icon-button with a heart icon">
                    <mat-icon style="color: green !important;">help</mat-icon>
                  </button>

                  <button (click)="mainMenu = false" mat-icon-button color="warn"
                    aria-label="Example icon-button with a heart icon">
                    <mat-icon style="color: grey !important;">keyboard_arrow_right</mat-icon>
                  </button>

                  <!-- <button class="fullscreen" (click)="openFullscreen()" mat-icon-button color="warn" aria-label="aria">
                        <mat-icon style="color: grey !important;">fullscreen</mat-icon>
                      </button> -->

                </mat-toolbar>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>



  </mat-sidenav-content>

</mat-sidenav-container>

<div *ngIf="showHotKeys" class="dialog-overlay">
  <div class="hotkeys-close" (click)="showHotKeys = false">x</div>
  <div class="dialog-content">
    <img src="assets/img/hotkeys8.png" alt="">
  </div>
</div>